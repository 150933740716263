<!--
 * @Descripttion:起草单位基本查询 
 * @version:1.0.0
 * @Author: huchongyuan
 * @Date: 2021-03-11 14:28:33
 * @LastEditors: huchongyuan
 * @LastEditTime: 2021-04-20 23:49:37
-->
<template>
    <div class="DraftingUnit">
      <div class="DraftingUnitHeader">
         <Form ref="formInline" label-position="right" :label-width="100" inline>
            <FormItem prop="authorDep" label="起草单位">
               <Input type="text" v-model="authorDep" placeholder="请输入起草单位" />
            </FormItem>
            <QueryParam ref="QueryParam" />
            <FormItem>
               <Button type="primary" class="leftBtn" @click="query">查询</Button>
               <Button @click="handleReset">重置</Button>
               <!-- <Button type="primary" style="margin-left:15px" @click="toUpperQuery">高级查询</Button> -->
            </FormItem>
         </Form>
      </div>
      <div class="DraftingUnitContent">  
         <QueryResult ref="QueryResult" :columns="columns" />
      </div>
      <statisticsModal ref="statisticsModal" />
      <PdfModal ref="PdfModal" />
      <ModifyModal ref="ModifyModal" />
    </div>
</template>
<script>
import QueryResult from '@/components/QueryResult';
import QueryParam from '@/components/QueryParam';
import DraftingUnit from '@/api/DraftingUnit';
import statisticsModal from '@/components/statisticsModal';
import PdfModal from '@/components/PdfModal';
import ModifyModal from '@/components/ModifyModal';
export default {
   name:"DraftingUnit",
   data(){
      return {
         "authorDep":"",
         "columns":[
            {"title":"序号","key":"indexNo","width":70},
            {"title":"起草单位","key":"authorDep","width":600},
            {"title":"标准号","key":"standNo",
            "width":220,
               "render":(h, params) => {
                  var value = params["row"]["standNo"];
                  let name =  params["row"]["standName"];
                  return h('Tooltip',{props:{placement: 'top'}},[
                     h('a', {
                           on: {
                              click: () => {
                                 this.$refs["statisticsModal"].open({
                                    "standNo":value,
                                     "modalName":value+"　　　"+name
                                 });
                              }
                           }
                        }, value),
                     h('span', {slot: 'content', style: {whiteSpace: 'normal', wordBreak: 'break-all'}}, "点击查询标准详细数据")
                     ]);
               }
            },
            {"title":"标准名称","key":"standName",
               "render":(h, params) => {
                  let value = params["row"]["standName"];
                  let fjUrl = params["row"]["fjUrl"];
                  return h('Tooltip',{props:{placement: 'top'}},[
                     h('a', {
                           on: {
                              click: () => {
                                 this.$refs["PdfModal"].open(fjUrl);
                              }
                           }
                        }, value),
                      h('span', {slot: 'content', style: {whiteSpace: 'normal', wordBreak: 'break-all'}}, "点击查询标准原文")
                     ]);
               }
            },
            {"title":"提出修订意见",
               "width":150,
               "render":(h, params) => {
                  let {table,tableId,standNo,standName,authorDep} = params['row'];
                  return h('div', [
                     h('a', {
                           on: {
                              click: () => {
                                this.$refs["ModifyModal"].open({
                                     "table":table,
                                     "tableId":tableId,
                                     "standNo":standNo,
                                     "standName":standName,
                                     "otherInfo":authorDep
                                  });
                              }
                           }
                        }, '提出修订意见')
                     ]);
               }
            }
         ]
      }
   },
   mounted(){
      
   },
   activated(){
      // 获取查询参数;
      if(this.$route.params.standName){
         let {standName} = this.$route.params;
         this.$refs["QueryParam"].setParam({standName})
      }
      if(this.$route.params.standNo){
        let {standNo} = this.$route.params;
        this.$refs["QueryParam"].setParam({standNo})
      }
      if(this.$route.params.query && this.$route.params.query=="1"){
         this.query();
      }else{
         this.handleReset();
          this.$refs["QueryResult"].query(DraftingUnit.query,{
             "standNo":"aaa",
             "standName":"",
             "standClass":"-1",
             "authorDep":""
          },true);
      }
   },
   components:{
      "QueryResult":QueryResult,
      "QueryParam":QueryParam,
      "statisticsModal":statisticsModal,
      "PdfModal":PdfModal,
      "ModifyModal":ModifyModal
   },
   methods:{
      query(){
         let queryParam = this.$refs["QueryParam"].getParam();
         let authorDep = this.authorDep;
         if(this.authorDep == "" && queryParam["standNo"]=="" && queryParam["standName"]==""){
            this.$Message.error('请输入关键字进行查询');
         }else{
            this.$refs["QueryResult"].query(DraftingUnit.query,{authorDep,...queryParam},true);
         }
      },
      // 跳转到高级查询;
      toUpperQuery(){
         this.$router.push({"name":"DraftingAdvancedUnit"});
      },
      handleReset(){
         this.$set(this,'authorDep',"");
         this.$refs["QueryParam"].reset();
      }
   }
}
</script>
<style lang="less">
.DraftingUnit{
   .DraftingUnitHeader{
      height:65px;
      .ivu-form-item{
         float:left;
      }
      .last.ivu-form-item{
         margin-left:85px;
         margin-right:0px;
      }
      .leftBtn{
         margin-right:20px;
      }
   }
}
</style>
